import React from "react";
import CrmTimeline from "./CrmTimeline";
import { useParams } from "react-router";
import { ApiHook } from "../../hooks/apiHook";

const LeadsDetails = () => {
  const params = useParams();

  //----------------------------------------- API ------------------------------------------

  const details = ApiHook.CallLeadDetails(params.id);

  return (
    <>
      <CrmTimeline />
      <div className="crm-time-line-sec">
        <div className="time-line-form joinings_viewBox">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped bg-success"
              role="progressbar"
              style={{
                width: `${details.data?.leadCompletion?.leadCompletion}%`,
                backgroundColor: `${details.data?.leadCompletion?.color}`,
              }}
              aria-valuenow={details.data?.leadCompletion?.leadCompletion}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <b>{`${details.data?.leadCompletion?.leadCompletion}%`}</b>
            </div>
          </div>
          <table className="time-line-table">
            <tbody>
              {details &&
                details.data &&
                details.data.details &&
                Object.entries(details.data.details).map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default LeadsDetails;
